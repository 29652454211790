import { getMe } from '@brainstud/academy-api';
import { MainView } from '@brainstud/blended-learning-catalog-panel/Views/MainView';
import { CatalogLayout } from 'Layouts/CatalogLayout/CatalogLayout';
import type { GetServerSideProps } from 'next';
import { NextPageWithLayout } from 'pages/_app';
import { getRootDomain } from 'Utils';
import { toLayout } from 'Utils/toLayout';

const FrontPage: NextPageWithLayout = () => <MainView />;
FrontPage.getLayout = toLayout(CatalogLayout);

/**
 * This frontpage is used to check whether the domain (i.e. portal) has been configured
 * to accept guests. If so, it will show the blended learning catalog.
 * Else, it will redirect to the /auth page.
 */
export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const domainName = getRootDomain(req.headers.host);
  if (domainName) {
    const [me] = await getMe(domainName);
    const portal = me.portal?.();
    if (!portal?.accessRequirements?.accountTypes?.includes('guest')) {
      return {
        redirect: {
          destination: '/auth',
          permanent: false,
        },
      };
    }
  }
  return {
    props: {},
  };
};

export default FrontPage;
